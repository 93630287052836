import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import auth from "../../../Account/Auth";
import be from "../../../BE";
import UpgradeBadge from "../../../Utilities/UpgradePath/UpgradeBadge";

function Calendar({ availability, setCalendar }) {
  const history = useHistory();
  const { t } = useTranslation(["eventCreator"]);
  const [availableCalendars, setAvailableCalendars] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAvailableCalendars = async () => {
      setLoading(true);
      try {
        await auth
          .isAuthenticated()
          .then(() => {
            be.get("jubilant-events", "/events/calendars", false, true, true)
              .then((response) => {
                console.log("[etype-creator][calendars] calendars fetched");
                setAvailableCalendars(response);
                if (!availability?.calendar && availableCalendars.length) {
                  setCalendar(response[0]);
                }
              })
              .catch((error) => {
                console.error(
                  "[etype-creator][calendars] network error while fetching calendars:",
                  error
                );
              });
          })
          .catch((error) => {
            console.error(
              "[etype-creator][calendars] authentication error while fetching calendars:",
              error
            );
            history.push({ pathname: "/login" });
          });
      } catch (error) {
        console.error(
          "[etype-creator][calendars] functional error while fetching calendars:",
          error
        );
      } finally {
        setLoading(false);
      }
    };

    getAvailableCalendars();
  }, []);

  return (
    <Form.Group as={Row} controlId="event-calendar" className="mt-3 mx-2">
      <Form.Label column sm={2} className="mt-auto">
        <UpgradeBadge featureName="event-calendar" />
        <br />
        {t("availability.calendar.title")}
      </Form.Label>
      <Col sm={10} lg={8}>
        <Row>
          <Row>
            <small style={{ color: "var(--color-secondary)" }}>
              {t("availability.calendar.hint")}
            </small>
          </Row>
          <Col xs={5}>
            <Form.Select
              className="dark-field text-center"
              aria-label="Calendar"
              defaultValue=""
              value={availability?.calendar || ""}
              onChange={(e) => {
                console.log(e.target.value);
                setCalendar(e.target.value);
              }}
            >
              <option value=""> all calendars </option>
              {!loading &&
                availableCalendars.length &&
                availableCalendars.map((calendar) => {
                  return (
                    <option key={`calendar-${calendar}`} value={calendar}>
                      {calendar}
                    </option>
                  );
                })}
            </Form.Select>
          </Col>
        </Row>
      </Col>
    </Form.Group>
  );
}

export default Calendar;
