export function createReport(eventData, auth, currentPage, eventName) {
  // Safely get user info from auth
  const userEmail = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "email"],
    fallback: "unknown",
  });
  const userName = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "name"],
    fallback: "unknown",
  });
  const userLanguage = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "language"],
    fallback: "unknown",
  });
  const userTimezone = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "timezone"],
    fallback: "unknown",
  });
  const userPlan = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "planStatus", "status"],
    fallback: "unknown",
  });

  // Basic sections always included
  let message = "\n**User Journey**\n";
  message += `- **Current Page:** ${currentPage}\n\n`;

  message += "**User Insights**\n";
  message += `- **Internal Username:** ${auth.getUsername()}\n`;
  message += `- **Customer Email (from Local Profile):** ${userEmail}\n`;
  message += `- **Customer Name:** ${userName}\n`;
  message += `- **Language:** ${userLanguage}\n`;
  message += `- **Timezone:** ${userTimezone}\n`;
  message += `- **Plan Status:** ${userPlan}\n\n`;

  // Append event-specific details
  switch (eventName) {
    case "checkout.closed": {
      const d = eventData?.data;
      const item = d?.items?.[0];
      message = "\n**## Checkout Closed**" + message; // Change heading as needed
      message += "**Checkout Details**\n";
      message += `- **Customer Email (from Paddle):** ${
        d?.customer?.email ?? "unknown"
      }\n`;
      message += `- **Checkout ID:** ${d?.id ?? "unknown"}\n`;
      message += `- **Transaction ID:** ${d?.transaction_id ?? "unknown"}\n`;
      message += `- **Status:** ${d?.status ?? "unknown"}\n`;
      message += `- **Product Name:** ${item?.product?.name ?? "unknown"}\n`;
      message += `- **Product Price Name:** ${item?.price_name ?? "unknown"}\n`;
      message += `- **Billing Cycle:** ${
        item?.billing_cycle?.frequency ?? "?"
      } ${item?.billing_cycle?.interval ?? "?"}(s)\n`;
      message += `- **Subtotal:** ${item?.totals?.subtotal ?? "?"} ${
        d?.currency_code ?? ""
      }\n`;
      message += `- **Tax:** ${item?.totals?.tax ?? "?"} ${
        d?.currency_code ?? ""
      }\n`;
      message += `- **Total:** ${item?.totals?.total ?? "?"} ${
        d?.currency_code ?? ""
      }\n`;
      break;
    }

    case "checkout.warning": {
      const w = eventData?.data;
      message = "\n**## Checkout Warning**" + message;
      message += "**Warning Details**\n";
      message += `- **Type:** ${w?.type ?? "unknown"}\n`;
      message += `- **Code:** ${w?.code ?? "unknown"}\n`;
      message += `- **Detail:** ${w?.detail ?? "none"}\n`;
      message += `- **Documentation:** ${w?.documentation_url ?? "none"}\n\n`;
      message += "**Errors**\n";
      if (Array.isArray(w?.errors) && w.errors.length > 0) {
        w.errors.forEach((err) => {
          message += `- **Field:** ${err.field ?? "unknown"} | **Message:** ${
            err.message ?? "none"
          }\n`;
        });
      } else {
        message += "No additional errors\n";
      }
      break;
    }

    case "checkout.error": {
      const e = eventData?.error;
      message = "\n**## Checkout Error**" + message;
      message += "**Error Details**\n";
      if (e) {
        message += `- **Type:** ${e.type ?? "unknown"}\n`;
        message += `- **Code:** ${e.code ?? "unknown"}\n`;
        message += `- **Detail:** ${e.detail ?? "none"}\n`;
        message += `- **Documentation:** ${e.documentation_url ?? "none"}\n`;
      } else {
        message += "No error details available\n";
      }
      break;
    }

    case "checkout.payment.failed": {
      const d = eventData?.data;
      const primaryItem = d?.items?.[0];

      message = "\n**## Checkout Payment Failed**" + message;
      message += "**Payment Failure Details**\n";
      message += `- **Customer Email (from Paddle):** ${
        d?.customer?.email ?? "unknown"
      }\n`;
      message += `- **Checkout ID:** ${d?.id ?? "unknown"}\n`;
      message += `- **Transaction ID:** ${d?.transaction_id ?? "unknown"}\n`;
      message += `- **Status:** ${d?.status ?? "unknown"}\n`;
      message += `- **Currency:** ${d?.currency_code ?? "unknown"}\n`;
      message += `- **Payment Method:** ${
        d?.payment?.method_details?.type ?? "unknown"
      }\n`;

      if (primaryItem) {
        message += "\n**Failed Product Details**\n";
        message += `- **Product Name:** ${
          primaryItem?.product?.name ?? "unknown"
        }\n`;
        message += `- **Quantity:** ${primaryItem?.quantity ?? "unknown"}\n`;
        message += `- **Subtotal:** ${primaryItem?.totals?.subtotal ?? "?"} ${
          d?.currency_code ?? ""
        }\n`;
        message += `- **Tax:** ${primaryItem?.totals?.tax ?? "?"} ${
          d?.currency_code ?? ""
        }\n`;
        message += `- **Discount:** ${primaryItem?.totals?.discount ?? "?"} ${
          d?.currency_code ?? ""
        }\n`;
        message += `- **Total:** ${primaryItem?.totals?.total ?? "?"} ${
          d?.currency_code ?? ""
        }\n`;
      }

      if (d?.discount) {
        message += "\n**Discount Applied**\n";
        message += `- **Discount ID:** ${d.discount.id ?? "unknown"}\n`;
        message += `- **Discount Code:** ${d.discount.code ?? "unknown"}\n`;
      }

      break;
    }

    case "checkout.loaded":
    case "checkout.customer.created":
    case "checkout.completed":
    default:
      break;
  }

  return message;
}
