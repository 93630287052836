import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function OwnerPic(props) {
  return (
    <img
      src={props.pic}
      width="96px"
      height="96px"
      className="tw-border-4 tw-mx-auto img-fluid rounded-circle"
      alt="avatar"
    />
  );
}

export default OwnerPic;
