import { useTranslation } from "react-i18next";
import { useCreateEventTypeStore } from "../../store";
import { Form, Row, Col } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import UpgradeBadge from "../../Utilities/UpgradePath/UpgradeBadge";

function RedirectionUrl() {
  const { t } = useTranslation(["eventCreator"]);
  const general = useCreateEventTypeStore((state) => state.general);
  const setRedirectionUrl = useCreateEventTypeStore(
    (state) => state.setRedirectionUrl
  );

  const handleChange = (e) => {
    let url = e.target.value;
    // if not secure url
    if (url && !url.includes("https://")) {
      // remove http://
      url = url.replace("http://", "");
      // add https://
      url = `https://${url}`;
    }
    setRedirectionUrl(`${url}`);
  };

  return (
    <Form.Group
      as={Row}
      controlId="event-redirection-url"
      className="my-3 ms-2 me-2"
    >
      <Form.Label column sm={12} lg={2}>
        <UpgradeBadge featureName="redirection-url" />
        <br />
        {t("general.redirectionUrl")}
      </Form.Label>

      <Col sm={10} lg={6}>
        <InputGroup className="flex-nowrap">
          <Form.Control
            type="url"
            className="dark-field"
            placeholder={t("general.redirectionUrlPlaceholder")}
            name="redirection-url"
            value={general?.redirectionUrl}
            onChange={handleChange}
          />
          <Button variant="secondary" onClick={() => setRedirectionUrl("")}>
            <i className="fa-solid fa-xmark"></i>
          </Button>
        </InputGroup>
      </Col>
    </Form.Group>
  );
}

export default RedirectionUrl;
