import { useCreateEventTypeStore } from "../../store";
import { useTranslation } from "react-i18next";
import UpgradeBadge from "../../Utilities/UpgradePath/UpgradeBadge";

function Add() {
  const { t } = useTranslation(["eventCreator"]);
  const addQuestion = useCreateEventTypeStore((state) => state.addQuestion);

  const componentStyle = {
    backgroundColor: "transparent",
    border: "none",
    margin: 0,
    padding: "0 0 0 20px",
    color: "var(--bs-primary)",
  };

  return (
    <>
      <button
        style={componentStyle}
        className="tw-text-left my-3"
        onClick={(e) => {
          e.preventDefault();
          addQuestion();
        }}
      >
        <UpgradeBadge featureName="event-questions" />
        <br />
        {t("surveys.addQuestion")}
      </button>
    </>
  );
}

export default Add;
