import { useTranslation } from "react-i18next";
import UpgradeBadge from "../../Utilities/UpgradePath/UpgradeBadge";

function Heading() {
  const { t } = useTranslation(["integrations"]);
  return (
    <>
      <div
        className="h3 my-3"
        style={{ fontFamily: "Roboto", fontWeight: 600 }}
      >
        <UpgradeBadge featureName="webhooks" />
        <br />
        {t("webhooks.title")}
      </div>
      <div style={{ fontFamily: "Roboto", color: "var(--color-secondary)" }}>
        {t("webhooks.subtitle")}
      </div>
    </>
  );
}

export default Heading;
