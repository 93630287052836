import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../UserContext";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Brand from "./Brand";
import TrialMessage from "./TrialMessage";
import LifetimeMessage from "./LifetimeMessage";
import auth from "../Account/Auth";
import Login from "../Login";
import Shortcuts from "./Shortcuts/_Shortcuts";
import ProfileMenu from "./ProfileMenu/_ProfileMenu";

function Navbar_(props) {
  // carico il context (lo userò per decidere cosa renderizzare nella navbar)
  const [user, setUser] = useContext(UserContext);
  const [agent, setAgent] = useState(null);
  const [impersonationUI, setImpersonationUI] = useState(null);
  const { t } = useTranslation(["navbar"]);
  const NewNavbar = window.calendbookUI.Navbar;
  const Logo = window.calendbookUI.LogoFull;
  useEffect(() => {
    if (user.isImpersonated) {
      setAgent(user?.impersonatingAgent);
    } else {
      setAgent(null);
    }
  }, [user.isImpersonated, user.impersonatingAgent]);

  const scrollTo = (_id) => {
    try {
      const element = document.getElementById(_id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else {
        throw new Error(`Element with id "${_id}" not found.`);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      {user?.logged ? (
        <Navbar
          style={{ background: "#242526" }}
          variant="dark"
          collapseOnSelect
          expand="md"
          className="tw-block md:tw-hidden"
        >
          <Container fluid>
            <Navbar.Brand>
              <Link to={{ pathname: "/" }}>
                <Logo width="164px" />
              </Link>
            </Navbar.Brand>
            {user.logged ? (
              <>
                <Shortcuts />
                <ProfileMenu agent={agent} />
              </>
            ) : (
              <>
                {window.location.pathname === "/login" && (
                  <>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="ms-auto me-0">
                        <Nav.Link
                          className="mx-1"
                          href="https://affiliates.calendbook.com"
                          target="_blank"
                        >
                          Earn with Affiliation
                        </Nav.Link>
                        <Nav.Link
                          className="mx-1"
                          href="https://medium.com/calendbook"
                          target="_blank"
                        >
                          {t("blog")}
                        </Nav.Link>
                        <Nav.Link className="mx-1 me-3" href="#pricing">
                          {t("pricing")}
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </>
                )}
              </>
            )}
          </Container>
        </Navbar>
      ) : (
        <>
          {window.location.pathname === "/login" && (
            <NewNavbar
              links={[
                {
                  name: "Pricing",
                  callback: () => {
                    scrollTo("pricing");
                  },
                },
                {
                  name: "Earn with Affiliation",
                  url: "https://affiliates.calendbook.com/",
                  newTab: true,
                },
                {
                  name: "Blog",
                  url: "https://medium.com/calendbook",
                  newTab: true,
                },
              ]}
            />
          )}
        </>
      )}
    </>
  );
}

export default Navbar_;
