import React from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import "bootstrap/dist/css/bootstrap.min.css";
import Color from "../../EventType/Color";
import Up from "../../EventType/Up";
import Middle from "../../EventType/Middle";

function _PublicEvent(props) {
  const multiLineTruncate = {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    color: "var(--color-secondary)",
  };

  const hasTrailingSlash = (str) => {
    return str.charAt(str.length - 1) === "/";
  };

  const removeLastChar = (str) => {
    return str.substr(0, str.length - 1);
  };

  const location = props.state.location;
  var locationValue = "";

  if (typeof location === "string") {
    console.info("[public-page][location] location type is 'string'...");
    locationValue = location;
  }
  if (typeof location === "object") {
    console.info("[public-page][location] location type is 'object'...");
    locationValue = location.value;
  }
  locationValue = DOMPurify.sanitize(locationValue);

  const description = DOMPurify.sanitize(props.state.description);

  return (
    <>
      <Link
        to={`${props.state.url}`}
        style={{
          minHeight: "100%",
          textDecoration: "none",
        }}
      >
        <div className="event-type-parent" style={{ height: "100%" }}>
          <Color enabled={true} color={props.state.color} />
          <div
            className="event-type-container"
            style={{
              height: "100%",
              background: "var(--color-background-1)",
            }}
          >
            <Up name={props.state.name} publicPage={true} />
            <Middle
              enabled={true}
              duration={props.state.duration}
              location={locationValue}
            />
            {props.state.description !== "" ? (
              <div style={multiLineTruncate}>{description}</div>
            ) : (
              <div> </div>
            )}
          </div>
        </div>
      </Link>
    </>
  );
}

export default _PublicEvent;
