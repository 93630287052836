import { useCreateEventTypeStore } from "../../../store";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UpgradeBadge from "../../../Utilities/UpgradePath/UpgradeBadge";

function LastAvailable() {
  const { t } = useTranslation(["eventCreator"]);
  const availability = useCreateEventTypeStore((state) => state.availability);
  const setApproval = useCreateEventTypeStore((state) => state.setApproval);

  return (
    <Form.Group as={Row} controlId="event-approval" className="mt-3 mx-2">
      <Form.Label column sm={2} className="mt-auto">
        <UpgradeBadge featureName="event-approval" />
        <br />
        {t("availability.approval.title")}
      </Form.Label>
      <Col sm={10} lg={6}>
        <Row>
          <Row>
            <small style={{ color: "var(--color-secondary)" }}>
              {t("availability.approval.hint")}
            </small>
          </Row>
          <Col>
            <Form.Check
              name="event-approval-checkbox"
              type="checkbox"
              label={t("availability.approval.label")}
              checked={availability?.needsApproval || false}
              onChange={(e) => setApproval(e.target.checked)}
            />
          </Col>
        </Row>
      </Col>
    </Form.Group>
  );
}

export default LastAvailable;
