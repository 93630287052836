import React, { useState } from "react";
import Badge from "react-bootstrap/Badge";
import "./upgrade-path.css";
import auth from "../../Account/Auth";
import features from "../features-list.json";
import { useTranslation } from "react-i18next";
import { featureKeysMap } from "./featureKeysMap.js";

// Helper function to determine feature access and the next available plan
const getFeatureAccessInfo = (userPlan, featureName, planOrder) => {
  let isLocked = false;
  let nextPlan = "Upgrade"; // Default message
  const userPlanIndex = planOrder.indexOf(userPlan);

  try {
    const featurePlans = features[featureName];
    isLocked = !featurePlans.includes(userPlan);

    if (isLocked) {
      const availablePlans = featurePlans.filter(
        (plan) => planOrder.indexOf(plan) > userPlanIndex
      );
      nextPlan = availablePlans.length > 0 ? availablePlans[0] : nextPlan;
    }
  } catch (e) {
    console.info(
      `[UpgradePath][${featureName}] Error getting feature access: `,
      e
    );
  }

  return { isLocked, nextPlan };
};

const UpgradePath = ({
  children,
  featureName,
  position,
  blur,
  customWidth,
}) => {
  const { t } = useTranslation(["features,common"]);

  const [showModal, setShowModal] = useState(false);

  const userPlan = auth.getRole(); // Fetch the current user's plan
  const planOrder = ["free", "premium", "professional", "LTD"]; // Predefined order of plans

  // Determine if feature is locked and find the next available plan
  const { isLocked, nextPlan } = getFeatureAccessInfo(
    userPlan,
    featureName,
    planOrder
  );

  // Map the featureName from the features-list.json to our i18n key
  const translationKey = featureKeysMap[featureName] || null;

  // If we have a matching translation key, retrieve the title/subtitle
  const featureTitle = translationKey
    ? t(`features:${translationKey}.title`)
    : t("features:locked.title");
  const featureSubtitle = translationKey
    ? t(`features:${translationKey}.subtitle`)
    : t("features:locked.subtitle");

  // Click event handler to open modal
  const handleOpenModal = (e) => {
    e.preventDefault(); // Prevent <a> navigation if present
    setShowModal(true);
  };
  // Close modal
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {/* Container that may show the overlay & badge if locked */}
      <div
        className="upgrade-path-container"
        style={customWidth ? { width: customWidth } : {}}
        // Only clickable if locked
        onClick={isLocked ? handleOpenModal : undefined}
      >
        {isLocked && (
          <>
            {/*
          <Badge
              className={`text-capitalize upgrade-path-badge badge-position-${position}`}
            >
              {nextPlan} Feature{" "}
            </Badge>
          */}
            <div
              className={
                blur
                  ? "upgrade-path-overlay upgrade-path-blur"
                  : "upgrade-path-overlay"
              }
            />
          </>
        )}
        {children}
      </div>

      {/* Modal */}
      {showModal && (
        <div
          id="progress-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="
            tw-fixed tw-inset-0 tw-z-50
            tw-flex tw-items-center tw-justify-center
            tw-bg-black/50
          "
        >
          {/* Modal dialog */}
          <div className="tw-relative tw-w-full tw-max-w-md tw-bg-white tw-rounded-lg tw-shadow dark:tw-bg-gray-700">
            {/* Close button (top-right corner) */}
            <button
              type="button"
              className="
                tw-absolute tw-top-3 tw-end-2.5
                tw-text-gray-400 tw-bg-transparent
                hover:tw-bg-gray-200 hover:tw-text-gray-900
                tw-rounded-lg tw-text-sm tw-w-8 tw-h-8 tw-ms-auto
                tw-inline-flex tw-justify-center tw-items-center
                dark:hover:tw-bg-gray-600 dark:hover:tw-text-white
              "
              onClick={handleCloseModal}
            >
              <svg
                className="tw-w-3 tw-h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </button>

            {/* Modal content */}
            <div className="tw-p-4 md:tw-p-5">
              {/* Dynamic title/description from i18n */}
              <h3 className="tw-text-xl tw-font-bold tw-text-gray-900 dark:tw-text-white tw-mb-2">
                {featureTitle}
              </h3>
              <p className="tw-text-gray-500 dark:tw-text-gray-400 tw-mb-6">
                {featureSubtitle}
              </p>

              {/* Modal footer - Cancel / Upgrade */}
              <div className="tw-flex tw-justify-between tw-space-x-4 rtl:tw-space-x-reverse">
                {/* Cancel button on the left */}
                <button
                  type="button"
                  className="
                    tw-py-2.5 tw-px-5 tw-text-sm tw-font-medium
                    tw-text-gray-900 focus:tw-outline-none
                    tw-bg-white tw-rounded-lg tw-border tw-border-gray-200
                    hover:tw-bg-gray-100 hover:tw-text-blue-700
                    focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100
                    dark:focus:tw-ring-gray-700 dark:tw-bg-gray-800
                    dark:tw-text-gray-400 dark:tw-border-gray-600
                    dark:hover:tw-bg-gray-700 dark:hover:tw-text-white
                  "
                  onClick={handleCloseModal}
                >
                  {t("common:cancel")}
                </button>

                {/* Upgrade button on the right */}
                <a
                  href="/upgrade"
                  className="
                    tw-text-white tw-bg-blue-700 hover:tw-bg-blue-800
                    focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-300
                    tw-font-medium tw-rounded-lg tw-text-sm
                    tw-px-5 tw-py-2.5 tw-text-center
                    dark:tw-bg-blue-600 dark:hover:tw-bg-blue-700
                    dark:focus:tw-ring-blue-800
                  "
                >
                  {t("common:upgrade")}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpgradePath;
