import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ThemeContext from "../ThemeContext";
import { useLocation, Link } from "react-router-dom";
import auth from "../Account/Auth";

function Sidebar({ isSidebarExpanded, setIsSidebarExpanded }) {
  const { t } = useTranslation(["sidebar"]);
  const [theme, setTheme] = useContext(ThemeContext);
  const [copied, setCopied] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const logoColor = "#f04c23";
  const Logo = window.calendbookUI.Logo;
  const Logotype = window.calendbookUI.Logotype;
  const userFullName = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "name"],
    fallback: "",
  });
  const userPlan = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "planStatus", "status"],
    fallback: "unknown",
  });
  const userPic = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "pic"],
    fallback: "/app-assets/img/standard-user-pic.png",
  });
  const userAgency = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "agencyId"],
    fallback: false,
  });
  const userSlug = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "slugName"],
    fallback: "None",
  });
  const username = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "username"],
    fallback: "",
  });

  const publicAddress = `/${userSlug !== "None" ? userSlug : username}`;

  function setDomTheme(theme) {
    const root = window.document.getElementById("root");
    const html = window.document.getElementById("html");
    root.setAttribute("data-theme", theme);
    html.setAttribute("data-theme", theme);
    window.localStorage.setItem("theme", theme);
  }

  // Function to handle the copy action
  const handleCopy = async () => {
    try {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const userSlug = auth.getProperty({
        storageKey: "userSession",
        path: ["profileObj", "slugName"],
        fallback: "None",
      });
      const username = auth.getProperty({
        storageKey: "userSession",
        path: ["profileObj", "username"],
        fallback: "",
      });
      const publicAddress = `${protocol}//${host}/${
        userSlug !== "None" ? userSlug : username
      }`;

      // Use the Clipboard API to write the text
      await navigator.clipboard.writeText(publicAddress);

      // Update the state to show the check icon
      setCopied(true);

      // After 2 seconds, revert the icon back
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy the link:", err);
    }
  };

  // Define the menu items
  const menuItems = [
    {
      href: "/",
      iconClass: "fas fa-home",
      label: t("navigation.home"),
      condition: true,
    },
    {
      href: "/calendarconnections",
      iconClass: "fas fa-calendar",
      label: t("navigation.calendars"),
      condition: true,
    },
    {
      href: "/leads",
      iconClass: "fa-solid fa-address-book",
      label: t("navigation.leads"),
      condition: true,
    },
    {
      href: "/integrations",
      iconClass: "fas fa-plug",
      label: t("navigation.integrations"),
      condition: true,
    },
    {
      href: "/team",
      iconClass: "fas fa-users",
      label: t("navigation.team"),
      condition: true,
    },
    {
      href: "/agency",
      iconClass: "fa-solid fa-user-tie",
      label: t("navigation.agency"),
      condition: userAgency,
    },
    {
      href: "/embed",
      iconClass: "fas fa-code",
      label: t("navigation.embed"),
      condition: true,
    },
    {
      href: "/upgrade",
      iconClass: "far fa-credit-card",
      label: t("navigation.upgrade"),
      condition: userPlan !== "professional",
    },
  ];

  const location = useLocation();

  return (
    <>
      {window.location.pathname !== "/login" &&
        window.location.pathname !== "/onboarding" &&
        !isMobile &&
        username && (
          <aside
            id="sidebar"
            className={`tw-fixed tw-top-0 tw-left-0 tw-h-screen tw-transition-width tw-duration-300 ${
              isSidebarExpanded ? "tw-w-64" : "tw-w-16"
            } tw-bg-white  dark:tw-bg-gray-800 tw-border-r tw-border-gray-200 dark:tw-border-gray-700`}
            aria-label="Sidebar"
          >
            <div className="tw-flex tw-flex-col tw-overflow-y-auto tw-py-10 tw-px-3 tw-h-full">
              <Link
                to="/"
                className={`tw-flex tw-flex-row tw-items-center tw-justify-center`}
              >
                <Logo width="33px" fillColor={logoColor} height="30px" />
                {isSidebarExpanded && (
                  <span className="tw-mb-1.5 tw-text-xl tw-font-bold ">
                    <Logotype
                      fillColor={logoColor}
                      height="25px"
                      width="120px"
                    />
                  </span>
                )}
              </Link>
              <ul className="tw-flex-1 tw-mt-10">
                {menuItems
                  .filter((item) => item.condition)
                  .map((item, index) => {
                    const isActive = location.pathname === item.href;
                    return (
                      <li key={index}>
                        <Link
                          to={item.href}
                          className={`tw-flex tw-items-center tw-p-2 tw-rounded-lg tw-transition tw-duration-75 hover:tw-bg-gray-200 dark:hover:tw-bg-gray-700 ${
                            isActive
                              ? ""
                              : "tw-text-gray-400 dark:tw-text-gray-100"
                          } ${isSidebarExpanded ? "" : "tw-justify-center"}`}
                        >
                          <i
                            className={`${item.iconClass} tw-text-lg tw-w-7 tw-text-center`}
                            style={{ color: isActive ? logoColor : undefined }}
                          ></i>
                          {isSidebarExpanded && (
                            <span className="tw-ml-3 tw-text-gray-400">
                              {item.label}
                            </span>
                          )}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
              <div className="tw-my-3">
                {/* Profile Obj */}
                <Link
                  to={publicAddress}
                  className={`tw-flex tw-my-2 tw-items-center ${
                    isSidebarExpanded ? "tw-px-3" : "tw-pl-1 tw-pr-0"
                  } tw-py-2 tw-rounded hover:tw-bg-gray-50 dark:hover:tw-bg-gray-600`}
                >
                  <img
                    src={userPic}
                    className="tw-mr-3 tw-w-8 tw-h-8 tw-rounded-full"
                    alt={userFullName}
                  />
                  {isSidebarExpanded && (
                    <div>
                      <div className="tw-text-left">
                        <div className="tw-font-semibold tw-leading-none tw-text-gray-900 dark:tw-text-white tw-mb-0.5">
                          {userFullName}
                        </div>
                        <div className="tw-text-sm tw-text-primary-700 dark:tw-text-primary-400">
                          {userPlan}
                        </div>
                      </div>
                    </div>
                  )}
                </Link>

                {/* Copy Link */}
                <button
                  onClick={handleCopy}
                  className={`tw-flex tw-items-center tw-py-1.5 ${
                    isSidebarExpanded ? "tw-px-5" : "tw-px-3"
                  } tw-text-sm tw-text-gray-400 hover:tw-text-gray-900 dark:hover:tw-text-white`}
                  type="button"
                >
                  <i
                    className={
                      copied
                        ? "far fa-check-circle tw-w-5 tw-h-5 tw-content-center tw-text-center"
                        : "fas fa-link tw-w-5 tw-h-5 tw-content-center tw-text-center"
                    }
                  ></i>
                  {isSidebarExpanded && (
                    <span className="tw-ml-3">{t("functions.copyLink")}</span>
                  )}
                </button>

                {/* Settings */}
                <Link
                  to="/settings"
                  className={`tw-flex tw-items-center tw-py-1 tw-rounded-lg ${
                    location.pathname === "/settings"
                      ? ""
                      : "tw-text-gray-400 hover:tw-text-gray-900 dark:hover:tw-text-white"
                  } ${
                    isSidebarExpanded
                      ? "tw-px-5"
                      : "tw-ml-1.5 tw-px-3 tw-justify-center"
                  }`}
                >
                  <i
                    className={`fas fa-cog tw-text-sm tw-w-5 tw-h-5 tw-content-center tw-text-center`}
                    style={{
                      color:
                        location.pathname === "/settings"
                          ? logoColor
                          : undefined,
                    }}
                  ></i>
                  {isSidebarExpanded && (
                    <span className="tw-ml-3 tw-text-sm">
                      {t("navigation.settings")}
                    </span>
                  )}
                </Link>

                {/* Theme Switcher */}
                {theme === "light" ? (
                  <button
                    onClick={() => {
                      setTheme("dark");
                      setDomTheme("dark");
                    }}
                    className={`tw-flex tw-items-center tw-py-1.5 ${
                      isSidebarExpanded ? "tw-px-5" : "tw-px-3"
                    } tw-text-sm tw-text-gray-400 hover:tw-text-gray-900 dark:hover:tw-text-white`}
                    type="button"
                  >
                    <i className="fas fa-moon text-center tw-w-5 tw-h-5 tw-content-center tw-text-center"></i>
                    {isSidebarExpanded && (
                      <span className="tw-ml-4 tw-text-sm">
                        {t("functions.darkTheme")}
                      </span>
                    )}
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setTheme("light");
                      setDomTheme("light");
                    }}
                    className={`tw-flex tw-items-center tw-py-1.5 ${
                      isSidebarExpanded ? "tw-px-5" : "tw-px-3"
                    } tw-text-sm tw-text-gray-400 hover:tw-text-gray-900 dark:hover:tw-text-white`}
                    type="button"
                  >
                    <i className="fas fa-sun text-center tw-text-sm tw-w-5 tw-h-5 tw-content-center tw-text-center"></i>
                    {isSidebarExpanded && (
                      <span className="tw-ml-3 tw-text-sm">
                        {t("functions.lightTheme")}
                      </span>
                    )}
                  </button>
                )}

                {/* Logout */}
                <Link
                  to="/logout"
                  className={`tw-flex tw-items-center tw-py-1 tw-rounded-lg ${
                    location.pathname === "/logout"
                      ? ""
                      : "tw-text-gray-400 hover:tw-text-gray-900 dark:hover:tw-text-white"
                  } ${
                    isSidebarExpanded
                      ? "tw-px-5"
                      : "tw-ml-1.5 tw-px-3 tw-justify-center"
                  }`}
                >
                  <i
                    className={`fas fa-sign-out-alt tw-text-sm tw-w-5 tw-h-5 tw-content-center tw-text-center`}
                    style={{
                      color:
                        location.pathname === "/logout" ? logoColor : undefined,
                    }}
                  ></i>
                  {isSidebarExpanded && (
                    <span className="tw-ml-3 tw-text-sm">
                      {t("navigation.logout")}
                    </span>
                  )}
                </Link>
              </div>

              {/* Collapse Toggle Button */}
              <button
                className={`tw-flex tw-items-center  tw-py-2 tw-rounded-lg tw-transition tw-duration-75 tw-text-gray-400 hover:tw-text-gray-900 dark:hover:tw-text-white tw-mt-auto ${
                  isSidebarExpanded ? "tw-px-6" : "tw-px-3 tw-justify-center"
                }`}
                onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
              >
                <i
                  className={`fas ${
                    isSidebarExpanded ? "fa-angle-left" : "fa-angle-right"
                  } tw-text-sm`}
                ></i>
                {isSidebarExpanded && (
                  <span className="tw-ml-3 tw-text-sm">
                    {t("functions.collapse")}
                  </span>
                )}
              </button>
            </div>
          </aside>
        )}
    </>
  );
}

export default Sidebar;
