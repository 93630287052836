export const featureKeysMap = {
  "unlimited-bookings": "bookings",
  "unlimited-event-types": "eventTypes",
  "custom-event-duration": "eventDuration",
  "custom-email-notifications": "emailNotifications",
  "salesforza-email-notifications": "salesforza",
  "custom-reminders": "reminders",
  team: "team",
  embed: "embed",
  "redirection-url": "redirectionUrl",
  "multiple-calendars": "calendarConnections",
  "zapier-integration": "zapier",
  webhooks: "webhooks",
  zoom: "zoom",
  stripe: "stripe",
  salesforza: "salesforza",
  "event-questions": "questions",
  "event-timeslot-hop": "timeslotHop",
  "event-booking-limit": "bookingLimit",
  "event-notice": "notice",
  "event-approval": "eventApproval",
  "event-last-availability": "lastAvailability",
};
