import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Header from "./Header/Header";
import Subscription from "./Subscription/Subscription";
import auth from "../Account/Auth";
import be from "../BE";
import LandingPage from "./Agency/LandingPage/LandingPage";
import { createReport } from "../Utilities/paddleAbandonedCheckoutReport";

const DEBUG =
  window.location.hostname === "localhost" ||
  window.location.hostname === "dev.calendbook.com";
const TEST_TOKEN = "test_2ee936d492e84b8c70ff282cacf";
const LIVE_TOKEN = "live_f9e2fd79f6f00aa1b9f8a4f55ec";

const TEST_YEARLY_PREMIUM = "pri_01hnd8w440pst7jaenb4pxjeap";
const TEST_MONTHLY_PREMIUM = "pri_01hnd8x6tzhbd13qw6d7tdkyn5";
const LIVE_YEARLY_PREMIUM = "pri_01hg8swx54tmf43w05cpgk97cc";
const LIVE_MONTHLY_PREMIUM = "pri_01hg8sw20z338km4mns1n08bxg";

const TEST_YEARLY_PROFESSIONAL = "pri_01hrc18ywbwns9mev9kqk54e03";
const TEST_MONTHLY_PROFESSIONAL = "pri_01hrc188kzp7m8x2paw3x6chwq";
const LIVE_YEARLY_PROFESSIONAL = "pri_01hrc1cdmrhmnz9t7xpz1jp15y";
const LIVE_MONTHLY_PROFESSIONAL = "pri_01hrc1bfh4v2whzkvy9d4db067";

const TEST_YEARLY_AGENCY_TIER_1 = "pri_01hpppgpswcs3m0bgsv6y4s5w7";
const TEST_YEARLY_AGENCY_TIER_2 = "pri_01hpppj8gv63sqrpfy0m903y54";
const TEST_YEARLY_AGENCY_TIER_3 = "pri_01hpppmdaz4wfanashe34nrqn0";
const TEST_MONTHLY_AGENCY_TIER_1 = "pri_01hpppfymkva8vdrkdc9txpzya";
const TEST_MONTHLY_AGENCY_TIER_2 = "pri_01hppphhxptgqa11m9rqyfbte9";
const TEST_MONTHLY_AGENCY_TIER_3 = "pri_01hpppkttbrjt8trn5jmy73ks1";
const LIVE_YEARLY_AGENCY_TIER_1 = "pri_01hprnktqvvgf6akr4h7sb5nmg";
const LIVE_YEARLY_AGENCY_TIER_2 = "pri_01hprnpk590pqkqc02jzhga165";
const LIVE_YEARLY_AGENCY_TIER_3 = "pri_01hprns7fxfacgn6mkjv4qwz2h";
const LIVE_MONTHLY_AGENCY_TIER_1 = "pri_01hprnjbazy7hftyh1cxvx8qtp";
const LIVE_MONTHLY_AGENCY_TIER_2 = "pri_01hprnnkjrcgt8mv7vy8s4fp5k";
const LIVE_MONTHLY_AGENCY_TIER_3 = "pri_01hprnr0jz2q72fqgn1z2wg0ww";

function Upgrade({ featureName }) {
  const PricingCard = window.calendbookUI.PricingCard;
  const Button = window.calendbookUI.Button;

  const history = useHistory();

  try {
    window.tidioChatApi.show();
  } catch {}
  const { i18n, t } = useTranslation(["landing", "common", "upgrade"]);
  const [loading, setLoading] = useState(false);
  const [paddle, setPaddle] = useState();
  const [view, setView] = useState(
    window.location.hash === "#agency" ? "agency" : "upgrade"
  );
  const [subscription, setSubscription] = useState({});
  const [pricingCadency, setPricingCadency] = useState("yearly");
  const [customerId, setCustomerId] = useState("");

  const userPlan = auth.getProperty({
    // get user role/plan
    storageKey: "userSession",
    path: ["profileObj", "planStatus", "status"],
    fallback: "unknown",
  });
  const getUserLanguage = () => {
    try {
      return i18n.language.substring(0, 2);
    } catch (e) {
      return "en";
    }
  };

  var createNestedObject = function (base, names) {
    // Usage:
    // createNestedObject( window, ["shapes", "triangle", "points"] );
    // Now window.shapes.triangle.points is an empty object, ready to be used.
    for (var i = 0; i < names.length; i++) {
      base = base[names[i]] = base[names[i]] || {};
    }
  };

  const sleep = (ms) => {
    /* usage: await sleep(3000); */
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getSubscriptions = async () => {
    setLoading(true);
    // check if subscription has extra fields
    // if yes, the customer already has a subscription
    function checkForAdditionalFields(
      response,
      reference = { customerId: null }
    ) {
      for (let key in response) {
        if (!(key in reference)) {
          console.log(`[upgrade][subscription] extra fields found`);
          return true;
        }
      }
      return false;
    }

    await auth
      .isAuthenticated()
      .then(
        await be
          .get("payment-jubilant", "/subscriptions", false, true, true)
          .then((response) => {
            setLoading(false);
            console.log(response);
            if (response?.customerId) {
              setCustomerId(response?.customerId);
            }
            if (checkForAdditionalFields(response, { customerId: null })) {
              //setView("manage");
              setSubscription(response);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          })
      )
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const sendAbandonedCartEvent = async (body) => {
    await auth
      .isAuthenticated()
      .then(
        await be
          .post("payment-jubilant", "/payments/abandoned", body, true, true)
          .then((response) => {
            console.log(
              "[upgrade][subscription] sent bandoned cart event: ",
              body
            );
          })
          .catch((error) => {
            console.log(error);
          })
      )
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    document.title = "Calendbook - Upgrade";
    initializePaddle({
      environment: DEBUG ? "sandbox" : "production",
      checkout: {
        settings: {
          displayMode: "overlay",
          theme: "light",
          locale: getUserLanguage(),
        },
      },
      token: DEBUG ? TEST_TOKEN : LIVE_TOKEN,
      eventCallback: async function (data) {
        const currentPage = window.location.href;

        // Create the message
        const eventMessage = createReport(data, auth, currentPage, data.name);

        switch (data.name) {
          case "checkout.closed":
            await sendAbandonedCartEvent({
              productId: data?.data?.items?.[0]?.product?.id,
              priceId: data?.data?.items?.[0]?.price_id,
              event: eventMessage,
            });
            break;

          case "checkout.warning":
            await sendAbandonedCartEvent({
              productId: data?.data?.items?.[0]?.product?.id,
              priceId: data?.data?.items?.[0]?.price_id,
              event: eventMessage,
            });
            break;

          case "checkout.error":
            await sendAbandonedCartEvent({
              productId: data?.data?.items?.[0]?.product?.id,
              priceId: data?.data?.items?.[0]?.price_id,
              event: eventMessage,
            });
            break;

          case "checkout.payment.failed":
            await sendAbandonedCartEvent({
              productId: data?.data?.items?.[0]?.product?.id,
              priceId: data?.data?.items?.[0]?.price_id,
              event: eventMessage,
            });
            break;

          case "checkout.completed":
            console.log(
              "[upgrade] checkout completed, updating user profile..."
            );
            setLoading(true);
            await sleep(5000);
            await auth.refresh();
            history.push("/settings#subscription");
            history.go(0);
            break;

          default:
            console.log(`Unhandled event: ${data.name}`);
        }
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });

    if (auth.sessionExist()) {
      // getSubscriptions();
    }
  }, []);

  const openCheckout = (product) => {
    const getSubscriptionPriceId = (_product) => {
      if (_product === "premium") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_PREMIUM;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_PREMIUM;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_PREMIUM;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_PREMIUM;
          }
        }
      }
      if (_product === "professional") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_PROFESSIONAL;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_PROFESSIONAL;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_PROFESSIONAL;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_PROFESSIONAL;
          }
        }
      }
      if (_product === "agency-tier-1") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_AGENCY_TIER_1;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_AGENCY_TIER_1;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_AGENCY_TIER_1;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_AGENCY_TIER_1;
          }
        }
      }
      if (_product === "agency-tier-2") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_AGENCY_TIER_2;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_AGENCY_TIER_2;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_AGENCY_TIER_2;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_AGENCY_TIER_2;
          }
        }
      }
      if (_product === "agency-tier-3") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_AGENCY_TIER_3;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_AGENCY_TIER_3;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_AGENCY_TIER_3;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_AGENCY_TIER_3;
          }
        }
      }
    };
    let checkoutObj = {
      items: [
        {
          priceId: getSubscriptionPriceId(product),
          quantity: 1,
        },
      ],
      customData: {
        tolt_referral: window.tolt_referral,
      },
    };
    if (customerId) {
      createNestedObject(checkoutObj, ["customer", "id"]);
      checkoutObj["customer"]["id"] = customerId;
    } else if (auth.getEmail()) {
      createNestedObject(checkoutObj, ["customer", "email"]);
      checkoutObj["customer"]["email"] = auth.getEmail();
    } else {
      createNestedObject(checkoutObj, ["customer", "email"]);
      checkoutObj["customer"]["email"] = "your@email.com";
    }
    paddle?.Checkout.open(checkoutObj);
  };

  return (
    <div className="container">
      {loading ? (
        <Spinner
          style={{ margin: "150px calc(50% - 16px)" }}
          animation="border"
          size="lg"
        />
      ) : (
        <>
          {userPlan === "professional" && (
            <div className="tw-justify-center tw-text-center tw-my-20">
              <h2 className="tw-text-4xl tw-font-extrabold">Awesome!</h2>
              <h3 className="tw-text-xl"> You are on the top Plan! ❤️</h3>
              <p>
                You can manage your subscription in the{" "}
                <a href="/settings#subscription" className="tw-underline">
                  settings page
                </a>
              </p>
            </div>
          )}
          {userPlan !== "professional" && <Header view={view} />}
          {view === "upgrade" && (
            <Row className="my-5 mx-auto" style={{ maxWidth: 900 }}>
              {userPlan !== "professional" && (
                <div className="tw-my-20 tw-mx-auto tw-w-max tw-bg-gray-200 tw-p-0.5 tw-gap-0.5 tw-rounded-full tw-flex tw-flex-row">
                  <Button
                    buttonText="Monthly"
                    color={
                      pricingCadency === "monthly" ? "dark" : "alternative"
                    }
                    onClick={() => setPricingCadency("monthly")}
                  />
                  <Button
                    buttonText="Yearly (50% Off)"
                    color={pricingCadency === "yearly" ? "dark" : "alternative"}
                    onClick={() => setPricingCadency("yearly")}
                  />
                </div>
              )}
              <div className="tw-my-10 tw-items-center tw-justify-center md:tw-items-stretch tw-flex tw-flex-col md:tw-flex-row tw-gap-8">
                {(userPlan === "free" ||
                  userPlan === "LTD" ||
                  userPlan === "trial" ||
                  userPlan === "unknown") && (
                  <PricingCard
                    variant="secondary"
                    plan="Premium"
                    description="For Premium users"
                    currency={
                      i18n.language.substring(0, 2) === "en" ? "$" : "€"
                    }
                    price={pricingCadency === "monthly" ? "5" : "2"}
                    cadency="month"
                    callback={() => openCheckout("premium")}
                    features={[
                      "Everything in Free Plan plus...",
                      "Unlimited Event Types",
                      "10 Calendar Connections",
                      "Zoom Integration",
                      "Questions to Qualify Leads",
                      "Send email from your Gmail",
                    ]}
                  />
                )}
                {(userPlan === "free" ||
                  userPlan === "LTD" ||
                  userPlan === "trial" ||
                  userPlan === "premium" ||
                  userPlan === "unknown") && (
                  <PricingCard
                    variant="primary"
                    plan="Professional"
                    description="For Advanced users"
                    currency={
                      i18n.language.substring(0, 2) === "en" ? "$" : "€"
                    }
                    price={pricingCadency === "monthly" ? "10" : "5"}
                    cadency="month"
                    callback={() => openCheckout("professional")}
                    features={[
                      "Everything in Premium Plan plus...",
                      "Zapier & Webhook Integrations",
                      "Advanced Availability",
                      "Company Branding",
                      "Accept/Reject Schedulings",
                      "Whatsapp & Telegram Notifications",
                    ]}
                  />
                )}
              </div>
            </Row>
          )}
          {view === "agency" && (
            <Row className="my-5 mx-auto" style={{ maxWidth: 900 }}>
              <LandingPage openCheckout={openCheckout} />
            </Row>
          )}
          {view === "manage" && (
            <Subscription
              subscription={subscription}
              setSubscriptionsLoading={setLoading}
              getSubscriptions={getSubscriptions}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Upgrade;
