import React, { useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import be from "../BE";
import _OwnerInfo from "./OwnerInfo/_OwnerInfo";
import _EventTypes from "./EventTypes/_EventTypes";

function _PublicEvents(props) {
  try {
    window.tidioChatApi.hide();
  } catch {}
  const [eventTypes, setEventTypes] = React.useState({});
  const [ownerInfo, setOwnerInfo] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    document.title = "Calendbook - Public Page";
    const getPublicEvents = async () => {
      await be
        .get("eventtypes", "/public", { owner: props.match.params.user }, false)
        .then((response) => {
          setEventTypes(response.eventTypes);
          setOwnerInfo(response.ownerInfo);
          setLoading(false);
        })
        .catch((response) => {
          props.history.push("/404");
        });
    };
    getPublicEvents();
  }, []);

  return (
    <div className="tw-my-5 tw-container tw-max-w-3xl tw-mx-auto tw-px-4">
      {loading ? (
        <div className="tw-flex tw-justify-center tw-items-center tw-h-64">
          {/* Tailwind CSS Spinner */}
          <svg
            className="tw-animate-spin tw-h-10 tw-w-10 tw-text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            role="status"
            aria-label="Loading"
          >
            <circle
              className="tw-opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="tw-opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
        </div>
      ) : (
        <div>
          {/* Owner Information */}
          <div className="tw-my-3 tw-text-center">
            <_OwnerInfo ownerInfo={ownerInfo} />
          </div>

          {/* Event Types Grid */}
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4 tw-auto-rows-fr">
            <_EventTypes eventTypes={eventTypes} />
          </div>
        </div>
      )}
    </div>
  );
}

export default _PublicEvents;
