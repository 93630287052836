import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { initializePaddle } from "@paddle/paddle-js";
import { useTranslation } from "react-i18next";
import { Tab, Row, Col, Button } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import RedeemCode from "./RedeemCode/RedeemCode";
import CurrentFeatures from "./CurrentFeatures/CurrentFeatures";
import Subscription from "./Subscription/Subscription";
import be from "../../BE";
import auth from "../../Account/Auth";

const DEBUG =
  window.location.hostname === "localhost" ||
  window.location.hostname === "dev.calendbook.com";
const TEST_TOKEN = "test_2ee936d492e84b8c70ff282cacf";
const LIVE_TOKEN = "live_f9e2fd79f6f00aa1b9f8a4f55ec";

const TEST_YEARLY_PREMIUM = "pri_01hnd8w440pst7jaenb4pxjeap";
const TEST_MONTHLY_PREMIUM = "pri_01hnd8x6tzhbd13qw6d7tdkyn5";
const LIVE_YEARLY_PREMIUM = "pri_01hg8swx54tmf43w05cpgk97cc";
const LIVE_MONTHLY_PREMIUM = "pri_01hg8sw20z338km4mns1n08bxg";

const TEST_YEARLY_PROFESSIONAL = "pri_01hrc18ywbwns9mev9kqk54e03";
const TEST_MONTHLY_PROFESSIONAL = "pri_01hrc188kzp7m8x2paw3x6chwq";
const LIVE_YEARLY_PROFESSIONAL = "pri_01hrc1cdmrhmnz9t7xpz1jp15y";
const LIVE_MONTHLY_PROFESSIONAL = "pri_01hrc1bfh4v2whzkvy9d4db067";

const TEST_YEARLY_AGENCY_TIER_1 = "pri_01hpppgpswcs3m0bgsv6y4s5w7";
const TEST_YEARLY_AGENCY_TIER_2 = "pri_01hpppj8gv63sqrpfy0m903y54";
const TEST_YEARLY_AGENCY_TIER_3 = "pri_01hpppmdaz4wfanashe34nrqn0";
const TEST_MONTHLY_AGENCY_TIER_1 = "pri_01hpppfymkva8vdrkdc9txpzya";
const TEST_MONTHLY_AGENCY_TIER_2 = "pri_01hppphhxptgqa11m9rqyfbte9";
const TEST_MONTHLY_AGENCY_TIER_3 = "pri_01hpppkttbrjt8trn5jmy73ks1";
const LIVE_YEARLY_AGENCY_TIER_1 = "pri_01hprnktqvvgf6akr4h7sb5nmg";
const LIVE_YEARLY_AGENCY_TIER_2 = "pri_01hprnpk590pqkqc02jzhga165";
const LIVE_YEARLY_AGENCY_TIER_3 = "pri_01hprns7fxfacgn6mkjv4qwz2h";
const LIVE_MONTHLY_AGENCY_TIER_1 = "pri_01hprnjbazy7hftyh1cxvx8qtp";
const LIVE_MONTHLY_AGENCY_TIER_2 = "pri_01hprnnkjrcgt8mv7vy8s4fp5k";
const LIVE_MONTHLY_AGENCY_TIER_3 = "pri_01hprnr0jz2q72fqgn1z2wg0ww";

const rulerStyle = {
  borderColor: "var(--color-ruler)",
  marginTop: 10,
  marginBottom: 0,
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

function SubscriptionPanel() {
  const PricingCard = window.calendbookUI.PricingCard;
  const Button = window.calendbookUI.Button;

  const history = useHistory();

  const { i18n, t } = useTranslation(["settings", "common"]);
  const [subscription, setSubscription] = useState({});
  const [paddle, setPaddle] = useState();
  const [customerId, setCustomerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [pricingCadency, setPricingCadency] = useState("yearly");
  const userPlan = auth.getProperty({
    storageKey: "userSession",
    path: ["profileObj", "planStatus", "status"],
    fallback: "unknown",
  });

  const getUserLanguage = () => {
    try {
      return i18n.language.substring(0, 2);
    } catch (e) {
      return "en";
    }
  };

  const getSubscriptions = async () => {
    setLoading(true);

    function checkForAdditionalFields(
      response,
      reference = { customerId: null }
    ) {
      for (let key in response) {
        if (!(key in reference)) {
          console.log(`[upgrade][subscription] extra fields found`);
          return true;
        }
      }
      return false;
    }

    try {
      await auth.isAuthenticated();
      const response = await be.get(
        "payment-jubilant",
        "/subscriptions",
        false,
        true,
        true
      );
      setLoading(false);
      console.log(response);
      if (response?.customerId) {
        setCustomerId(response?.customerId);
      }
      if (checkForAdditionalFields(response, { customerId: null })) {
        setSubscription(response);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const sendAbandonedCartEvent = async (body) => {
    try {
      await auth.isAuthenticated();
      const response = await be.post(
        "payment-jubilant",
        "/payments/abandoned",
        body,
        true,
        true
      );
      console.log(
        "[upgrade][subscription] sent abandoned cart event: ",
        response
      );
    } catch (error) {
      console.log(error);
    }
  };

  var createNestedObject = function (base, names) {
    // Usage:
    // createNestedObject( window, ["shapes", "triangle", "points"] );
    // Now window.shapes.triangle.points is an empty object, ready to be used.
    for (var i = 0; i < names.length; i++) {
      base = base[names[i]] = base[names[i]] || {};
    }
  };

  useEffect(() => {
    initializePaddle({
      environment: DEBUG ? "sandbox" : "production",
      checkout: {
        settings: {
          displayMode: "overlay",
          theme: "light",
          locale: getUserLanguage(),
        },
      },
      token: DEBUG ? TEST_TOKEN : LIVE_TOKEN,
      eventCallback: async function (data) {
        switch (data.name) {
          case "checkout.loaded":
            console.log("Checkout opened");
            break;
          case "checkout.customer.created":
            console.log("Customer created");
            break;
          case "checkout.closed":
            const status = data?.data?.status;
            if (status === "draft") {
              const body = {
                productId: data?.data?.items[0]?.product?.id,
                priceId: data?.data?.items[0]?.price_id,
              };
              await sendAbandonedCartEvent(body);
            }
            break;
          case "checkout.completed":
            console.log(
              "[upgrade] checkout completed, updating user profile..."
            );
            setLoading(true);
            await sleep(5000);
            await auth.refresh();
            //await getSubscriptions();
            history.push("/settings#subscription");
            history.go(0);
            break;
          default:
        }
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });

    if (auth.sessionExist()) {
      getSubscriptions();
    }
  }, []);

  const openCheckout = (product) => {
    const getSubscriptionPriceId = (_product) => {
      if (_product === "premium") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_PREMIUM;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_PREMIUM;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_PREMIUM;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_PREMIUM;
          }
        }
      }
      if (_product === "professional") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_PROFESSIONAL;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_PROFESSIONAL;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_PROFESSIONAL;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_PROFESSIONAL;
          }
        }
      }
      if (_product === "agency-tier-1") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_AGENCY_TIER_1;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_AGENCY_TIER_1;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_AGENCY_TIER_1;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_AGENCY_TIER_1;
          }
        }
      }
      if (_product === "agency-tier-2") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_AGENCY_TIER_2;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_AGENCY_TIER_2;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_AGENCY_TIER_2;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_AGENCY_TIER_2;
          }
        }
      }
      if (_product === "agency-tier-3") {
        if (DEBUG) {
          if (pricingCadency === "monthly") {
            return TEST_MONTHLY_AGENCY_TIER_3;
          }
          if (pricingCadency === "yearly") {
            return TEST_YEARLY_AGENCY_TIER_3;
          }
        } else {
          if (pricingCadency === "monthly") {
            return LIVE_MONTHLY_AGENCY_TIER_3;
          }
          if (pricingCadency === "yearly") {
            return LIVE_YEARLY_AGENCY_TIER_3;
          }
        }
      }
    };
    let checkoutObj = {
      items: [
        {
          priceId: getSubscriptionPriceId(product),
          quantity: 1,
        },
      ],
      customData: {
        tolt_referral: window.tolt_referral,
      },
    };
    if (customerId) {
      createNestedObject(checkoutObj, ["customer", "id"]);
      checkoutObj["customer"]["id"] = customerId;
    } else if (auth.getEmail()) {
      createNestedObject(checkoutObj, ["customer", "email"]);
      checkoutObj["customer"]["email"] = auth.getEmail();
    } else {
      createNestedObject(checkoutObj, ["customer", "email"]);
      checkoutObj["customer"]["email"] = "your@email.com";
    }
    paddle?.Checkout.open(checkoutObj);
  };

  return (
    <Tab.Pane eventKey="subscription">
      {(auth.isTrial() || auth.isFree() || auth.isLifetime()) && (
        <Row className="mb-4">
          <Col xs={12} md={12}>
            <div>
              <div className="tw-text-3xl tw-font-extrabold tw-leading-tight tw-racking-tight">
                {" "}
                You are a {userPlan} user{" "}
              </div>
              <p className="tw-mt-2 tw-text-base tw-font-normal tw-text-gray-500">
                Get the best offer from calendbook with Premium & Professional
                Plan!
              </p>
            </div>

            <div className="tw-my-20 tw-mx-auto tw-w-max tw-bg-gray-200 tw-p-0.5 tw-gap-0.5 tw-rounded-full tw-flex tw-flex-row">
              <Button
                buttonText="Monthly"
                color={pricingCadency === "monthly" ? "dark" : "alternative"}
                onClick={() => setPricingCadency("monthly")}
              />
              <Button
                buttonText="Yearly (50% Off)"
                color={pricingCadency === "yearly" ? "dark" : "alternative"}
                onClick={() => setPricingCadency("yearly")}
              />
            </div>

            <div className="tw-my-10 tw-items-center md:tw-items-stretch tw-flex tw-flex-col md:tw-flex-row tw-gap-8">
              <PricingCard
                variant="secondary"
                plan="Premium"
                description="For Premium users"
                currency={i18n.language.substring(0, 2) === "en" ? "$" : "€"}
                price={pricingCadency === "monthly" ? "5" : "2"}
                cadency="month"
                callback={() => openCheckout("premium")}
                features={[
                  "Everything in Free Plan plus...",
                  "Unlimited Event Types",
                  "10 Calendar Connections",
                  "Zoom Integration",
                  "Questions to Qualify Leads",
                  "Send email from your Gmail",
                ]}
              />
              <PricingCard
                variant="primary"
                plan="Professional"
                description="For Advanced users"
                currency={i18n.language.substring(0, 2) === "en" ? "$" : "€"}
                price={pricingCadency === "monthly" ? "10" : "5"}
                cadency="month"
                callback={() => openCheckout("professional")}
                features={[
                  "Everything in Premium Plan plus...",
                  "Zapier & Webhook Integrations",
                  "Advanced Availability",
                  "Company Branding",
                  "Accept/Reject Schedulings",
                  "Whatsapp & Telegram Notifications",
                ]}
              />
            </div>

            <RedeemCode />
          </Col>
        </Row>
      )}

      {(userPlan === "professional" || userPlan === "premium") && (
        <Row>
          <Col>
            <Subscription
              loadingSubscription={loading}
              subscription={subscription}
              setSubscriptionsLoading={setLoading}
              getSubscriptions={getSubscriptions}
            />
          </Col>
        </Row>
      )}
    </Tab.Pane>
  );
}

export default SubscriptionPanel;
