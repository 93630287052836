import React from "react";
import { Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import OwnerName from "./OwnerName";
import OwnerPic from "./OwnerPic";
import OwnerWelcome from "./OwnerWelcome";
import auth from "../../Account/Auth";

function _OwnerInfo(props) {
  return (
    <>
      {Object.keys(props?.ownerInfo).length > 0 && (
        <div>
          {props?.ownerInfo?.bannerPic && (
            <div className="tw-border-2 tw-rounded-xl tw-max-w-3xl tw-mx-auto">
              <img
                src={props?.ownerInfo?.bannerPic}
                className="tw-rounded-xl"
                width="100%"
                alt="banner"
              />
            </div>
          )}
          <div
            className={`${
              props?.ownerInfo?.bannerPic ? "tw-relative tw--mt-[60px]" : ""
            } tw-mb-8`}
          >
            <OwnerPic pic={props?.ownerInfo?.pic} />
          </div>
          <OwnerName name={props?.ownerInfo?.name} />
          <OwnerWelcome sentence={props?.ownerInfo?.sentence} />
        </div>
      )}
    </>
  );
}

export default _OwnerInfo;
