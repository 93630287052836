import React, { useCallback, useRef, useState } from "react";
import useClickOutside from "./useClickOutside";
import Form from "react-bootstrap/Form";
import UpgradePath from "../Utilities/UpgradePath/UpgradePath";
import UpgradeBadge from "../Utilities/UpgradePath/UpgradeBadge";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { useTranslation } from "react-i18next";

function Options({
  color,
  setColor,
  isBorder,
  setBorder,
  isResponsive,
  setResponsive,
  setFixed,
  hideDetails,
  setHideDetails,
}) {
  const { t } = useTranslation(["navbar"]);
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <p>
      <strong>{t("embed.options.title")}</strong>
      <Form.Switch
        label={t("embed.options.border")}
        checked={isBorder()}
        onClick={() => {
          isBorder() ? setBorder("none") : setBorder("1px solid black");
        }}
      />
      <Form.Switch
        label={t("embed.options.responsive")}
        checked={isResponsive()}
        onClick={() => {
          isResponsive() ? setFixed() : setResponsive();
        }}
      />
      <Form.Switch
        label="hide details card"
        checked={hideDetails}
        onClick={() => {
          hideDetails ? setHideDetails(false) : setHideDetails(true);
        }}
      />
      <UpgradePath featureName="embed" position="right">
        <UpgradeBadge featureName="embed" />
        <br />
        <div className="picker">
          <div
            className="swatch"
            style={{ backgroundColor: color }}
            onClick={() => toggle(true)}
          />
          <HexColorInput
            className="hex-input"
            color={color}
            onChange={setColor}
          />
          <span className="swatch-label">background</span>

          {isOpen && (
            <div className="popover" ref={popover}>
              <HexColorPicker
                style={{ width: 115, height: 115 }}
                color={color}
                onChange={setColor}
              />
            </div>
          )}
        </div>
      </UpgradePath>
    </p>
  );
}

export default Options;
