import { useState, useEffect } from "react";
import { useCreateEventTypeStore } from "../../../store";
import { useTranslation } from "react-i18next";
import UpgradeBadge from "../../../Utilities/UpgradePath/UpgradeBadge";

function DisabledDates() {
  const { t } = useTranslation(["eventCreator"]);
  const availability = useCreateEventTypeStore((state) => state.availability);
  const addDisabledDateRange = useCreateEventTypeStore(
    (state) => state.addDisabledDateRange
  );
  const setDisabledDateStart = useCreateEventTypeStore(
    (state) => state.setDisabledDateStart
  );
  const setDisabledDateEnd = useCreateEventTypeStore(
    (state) => state.setDisabledDateEnd
  );
  const removeDisabledDateRange = useCreateEventTypeStore(
    (state) => state.removeDisabledDateRange
  );

  // Maximum number of date ranges allowed
  const MAX_RANGES = 5;

  // State variable for date ranges
  const [dateRanges, setDateRanges] = useState([]);

  // State variable for error messages per date range
  const [errorMessages, setErrorMessages] = useState([]);

  // State variable for max ranges message
  const [maxRangesMessage, setMaxRangesMessage] = useState("");

  // Initialize date ranges from availability state
  useEffect(() => {
    if (
      availability.disabledDateRaw &&
      availability.disabledDateRaw.length > 0
    ) {
      // Deep copy to prevent mutating state directly
      setDateRanges(
        availability.disabledDateRaw.map((range) => ({ ...range }))
      );
      setErrorMessages(availability.disabledDateRaw.map(() => ""));
    } else {
      setDateRanges([]);
      setErrorMessages([]);
    }
  }, [availability.disabledDateRaw]);

  // Function to add a new date range
  const addDateRange = () => {
    if (dateRanges.length < MAX_RANGES) {
      setDateRanges([...dateRanges, { start: "", end: "" }]);
      setErrorMessages([...errorMessages, ""]);
      addDisabledDateRange(); // Update Zustand store
      setMaxRangesMessage(""); // Clear any existing message
    } else {
      setMaxRangesMessage(`You can only add up to ${MAX_RANGES} date ranges.`);
    }
  };

  // Function to remove a date range at a given index
  const removeDateRange = (index) => {
    const newDateRanges = dateRanges.filter((_, idx) => idx !== index);
    const newErrorMessages = errorMessages.filter((_, idx) => idx !== index);
    setDateRanges(newDateRanges);
    setErrorMessages(newErrorMessages);
    removeDisabledDateRange(index); // Update Zustand store
    setMaxRangesMessage(""); // Clear any existing message when a range is removed
  };

  // Handler for date range change
  const handleDateRangeChange = (index, field, value) => {
    const updatedRange = { ...dateRanges[index], [field]: value };

    // Validation: end date should not be before start date
    let errorMessage = "";
    if (
      field === "start" &&
      updatedRange.end &&
      new Date(updatedRange.end) < new Date(value)
    ) {
      updatedRange.end = value;
      setDisabledDateEnd(index, value); // Update Zustand store
    }
    if (
      field === "end" &&
      updatedRange.start &&
      new Date(value) < new Date(updatedRange.start)
    ) {
      errorMessage = "End date cannot be before start date.";
    }

    // Update state and store
    const newDateRanges = [...dateRanges];
    newDateRanges[index] = updatedRange;
    setDateRanges(newDateRanges);

    const newErrorMessages = [...errorMessages];
    newErrorMessages[index] = errorMessage;
    setErrorMessages(newErrorMessages);

    // Update Zustand store
    if (field === "start") {
      setDisabledDateStart(index, value);
    } else if (field === "end") {
      setDisabledDateEnd(index, value);
    }
  };

  return (
    <div className="tw-w-full flex-col sm:tw-inline-flex tw-mx-2 tw-my-4">
      <div className="sm:tw-w-12/12 lg:tw-w-2/12 tw-flex tw-items-center tw-mb-2 tw-px-3 tw-py-2">
        <label className="">
          <UpgradeBadge featureName="event-disabled-dates" />
          <br />
          {t("availability.disableDates.title")}
        </label>
      </div>
      <div className="tw-w-full tw-px-7">
        <div className="tw-w-10/12">
          <small style={{ color: "var(--color-secondary)" }}>
            {t("availability.disableDates.hint")}
          </small>
        </div>
        {dateRanges.length === 0 ? (
          <div className="tw-mt-2">
            <div className="tw-flex tw-items-center tw-space-x-2">
              <span>{t("availability.disableDates.add.click")}</span>
              <i
                className="fas fa-plus tw-text-blue-500 tw-cursor-pointer"
                onClick={addDateRange}
              ></i>
              <span>{t("availability.disableDates.add.range")}</span>
            </div>
            {maxRangesMessage && (
              <div className="tw-text-red-500 tw-mt-2">{maxRangesMessage}</div>
            )}
          </div>
        ) : (
          <div className="tw-w-10/12 tw-flex-col">
            {dateRanges.map((range, index) => (
              <div key={index} className="tw-mt-2">
                <div
                  id="date-range-picker"
                  className="tw-flex tw-items-center tw-space-x-4"
                >
                  <div className="tw-flex tw-flex-col tw-w-full">
                    <label
                      htmlFor={`datepicker-range-start-${index}`}
                      className="tw-text-xs tw-mb-1"
                    >
                      {t("availability.disableDates.start")}{" "}
                    </label>
                    <input
                      id={`datepicker-range-start-${index}`}
                      name="start"
                      type="date"
                      className="dark-field tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-p-2.5"
                      value={range.start}
                      onChange={(e) =>
                        handleDateRangeChange(index, "start", e.target.value)
                      }
                    />
                  </div>
                  <div className="tw-flex tw-flex-col tw-w-full">
                    <label
                      htmlFor={`datepicker-range-end-${index}`}
                      className="tw-text-xs tw-mb-1"
                    >
                      {t("availability.disableDates.end")}
                    </label>
                    <input
                      id={`datepicker-range-end-${index}`}
                      name="end"
                      type="date"
                      className="dark-field tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-w-full tw-p-2.5"
                      value={range.end}
                      onChange={(e) =>
                        handleDateRangeChange(index, "end", e.target.value)
                      }
                      min={range.start || undefined}
                    />
                  </div>
                  <i
                    style={{
                      border: "none",
                      background: "transparent",
                      color: "var(--color-text)",
                    }}
                    className="far fa-trash-alt tw-text-black tw-cursor-pointer tw-ml-2 tw-mt-4"
                    onClick={() => removeDateRange(index)}
                  ></i>
                </div>
                {errorMessages[index] && (
                  <small className="tw-text-red-500 tw-mt-2">
                    {errorMessages[index]}
                  </small>
                )}
              </div>
            ))}
            {dateRanges.length < MAX_RANGES && (
              <div className="tw-my-2">
                <div className="tw-flex tw-items-center tw-space-x-2">
                  <i
                    className="fas fa-plus tw-text-blue-500 tw-cursor-pointer"
                    onClick={addDateRange}
                  ></i>
                  <span>{t("availability.disableDates.add.another")}</span>
                </div>
              </div>
            )}
            {maxRangesMessage && (
              <div className="tw-text-red-500 tw-mt-2">{maxRangesMessage}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DisabledDates;
