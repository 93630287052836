import { useEffect, useState } from "react";
import { useCreateEventTypeStore } from "../../../store";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import UpgradeBadge from "../../../Utilities/UpgradePath/UpgradeBadge";

const determineMinQuantity = (_unit) => {
  switch (_unit) {
    case "minutes":
      return "0";
    case "hours":
      return "1";
    case "days":
      return "1";
    default:
      return "0";
  }
};
const determineMaxQuantity = (_unit) => {
  switch (_unit) {
    case "minutes":
      return "59";
    case "hours":
      return "23";
    case "days":
      return "30";
    default:
      return "0";
  }
};

const getUnit = () => {
  return document.getElementById(`notice-unit`)?.value;
};

function Notice() {
  const { t } = useTranslation(["eventCreator"]);
  const [minQuantity, setMinQuantity] = useState("0");
  const [maxQuantity, setMaxQuantity] = useState("0");
  const availability = useCreateEventTypeStore((state) => state.availability);
  const setNoticeQuantity = useCreateEventTypeStore(
    (state) => state.setNoticeQuantity
  );
  const setNoticeUnit = useCreateEventTypeStore((state) => state.setNoticeUnit);

  useEffect(() => {
    // setMinQuantity(determineMinQuantity(getUnit()));
    setMaxQuantity(determineMaxQuantity(getUnit()));
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const valueToSet = inputValue === "" ? minQuantity : inputValue;
    setNoticeQuantity(valueToSet);
    setNoticeUnit(document.getElementById("notice-unit").value);
  };

  const disableNotice = () => {
    setNoticeQuantity("0");
    setNoticeUnit("minutes");
  };

  return (
    <Form.Group as={Row} controlId="event-notice" className="mt-3 mx-2">
      <Form.Label column sm={2} className="mt-auto">
        <UpgradeBadge featureName="event-notice" />
        <br />
        {t("availability.notice.title")}
      </Form.Label>
      <Col sm={10} lg={6}>
        <Row>
          <Row>
            <small style={{ color: "var(--color-secondary)" }}>
              {t("availability.notice.hint")}
            </small>
          </Row>
          <Col xs={6}>
            <Form.Control
              id="notice-quantity"
              className="dark-field text-center"
              name="notice-value"
              type="number"
              min={minQuantity}
              max={maxQuantity}
              value={availability?.notice?.quantity || minQuantity}
              onChange={handleInputChange}
            />
          </Col>
          <Col xs={4} className="d-flex align-items-center">
            <Form.Select
              id="notice-unit"
              className="dark-field text-center"
              aria-label="Notice Unit"
              value={availability?.notice?.unit || "minutes"}
              onChange={(e) => {
                setNoticeUnit(e.target.value);
                setNoticeQuantity(
                  document.getElementById("notice-quantity").value
                );
                // setMinQuantity(determineMinQuantity(e.target.value));
                setMaxQuantity(determineMaxQuantity(e.target.value));
              }}
            >
              <option value="minutes">
                {t("availability.notice.units.minutes")}
              </option>
              <option value="hours">
                {t("availability.notice.units.hours")}
              </option>
              <option value="days">
                {t("availability.notice.units.days")}
              </option>
            </Form.Select>
            <Button
              style={{
                border: "none",
                background: "transparent",
                color: "var(--color-text)",
              }}
              onClick={disableNotice}
            >
              <i className="far fa-trash-alt my-auto"></i>
            </Button>
          </Col>
        </Row>
      </Col>
    </Form.Group>
  );
}

export default Notice;
