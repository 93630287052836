import React from "react";
import auth from "../../Account/Auth";
import features from "../features-list.json";

// Helper function to determine feature access and the next available plan
const getFeatureAccessInfo = (userPlan, featureName, planOrder) => {
  let isLocked = false;
  let nextPlan = "Upgrade"; // fallback if no higher plan is found
  const userPlanIndex = planOrder.indexOf(userPlan);

  try {
    const featurePlans = features[featureName];
    // If the user’s plan is not in the array for this feature, they are locked
    isLocked = !featurePlans.includes(userPlan);

    if (isLocked) {
      const availablePlans = featurePlans.filter(
        (plan) => planOrder.indexOf(plan) > userPlanIndex
      );
      // If the feature is available on higher plans, pick the first higher plan
      if (availablePlans.length > 0) {
        nextPlan = availablePlans[0];
      }
    }
  } catch (error) {
    console.info(
      `[PlanBadge][${featureName}] Error while determining feature access: `,
      error
    );
  }

  return { isLocked, nextPlan };
};

// Utility function to conditionally apply color classes
// If the plan is 'premium', we apply a "silver" color scheme. Otherwise, use the default "primary" scheme.
const getBadgeColorClasses = (plan) => {
  if (plan === "premium") {
    // "Silver" style for light and dark modes
    return `
      tw-bg-gray-300
      tw-text-gray-900
      dark:tw-bg-gray-700
      dark:tw-text-white
    `;
  }

  // Default "primary" style
  return `
    tw-bg-primary-100
    tw-text-primary-800
    dark:tw-bg-primary-900
    dark:tw-text-primary-300
  `;
};

const UpgradeBadge = ({ featureName }) => {
  const userPlan = auth.getRole();
  // The order of the plans, from lowest to highest
  const planOrder = ["free", "premium", "professional", "LTD"];

  // Get info on whether user is locked out and what the next plan is
  const { isLocked, nextPlan } = getFeatureAccessInfo(
    userPlan,
    featureName,
    planOrder
  );

  // If the user is not locked, we don't need to show a badge
  if (!isLocked) return null;

  return (
    <span
      className={`
        tw-text-nowrap
        tw-text-xs
        tw-font-medium
        tw-px-2.5
        tw-py-0.5
        tw-rounded
        ${getBadgeColorClasses(nextPlan)}
      `}
    >
      {nextPlan}
    </span>
  );
};

export default UpgradeBadge;
