import React from "react";
import { useCreateEventTypeStore } from "../../store";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import SortableQuestion from "./Question/SortableQuestion"; // Make sure this path is correct
import Add from "./Add";
import UpgradePath from "../../Utilities/UpgradePath/UpgradePath";

import {
  closestCenter,
  DndContext,
  useSensor,
  useSensors,
  TouchSensor,
  MouseSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";

function Surveys({ existingQuestions, existingFields, editing }) {
  const { t } = useTranslation(["eventCreator"]);
  const surveys = useCreateEventTypeStore((state) => state.surveys);
  const setSurveys = useCreateEventTypeStore((state) => state.setSurveys);

  const onDragEnd = (event) => {
    console.log(event);
    const { active, over } = event;
    if (active.id === over.id) return;

    let surveysArray = Object.entries(surveys).map(([key, value]) => ({
      id: key,
      ...value,
    }));

    const activeIndex = surveysArray.findIndex(
      (item) => item.order === active.id
    );
    const overIndex = surveysArray.findIndex((item) => item.order === over.id);

    if (activeIndex !== -1 && overIndex !== -1) {
      surveysArray = arrayMove(surveysArray, activeIndex, overIndex);
      surveysArray.map((survey, index) => (survey.order = index + 1));

      const newSurveys = surveysArray.reduce((acc, current) => {
        const { id, ...rest } = current;
        acc[id] = rest;
        return acc;
      }, {});

      console.log(newSurveys);

      setSurveys(newSurveys);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 10,
      },
    })
  );

  return (
    <>
      <Card
        style={{
          color: "var(--text-color)",
          background: "var(--color-background-1)",
        }}
        className="mb-2"
      >
        <Card.Header className="h3">{t("surveys.header")}</Card.Header>
        <Card.Body>
          <UpgradePath featureName="event-questions" position="right">
            <Accordion defaultActiveKey="0">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={Object.keys(surveys).map((key) => surveys[key].order)}
                  strategy={verticalListSortingStrategy}
                >
                  {Object.keys(surveys).map((key, index) => (
                    <SortableQuestion
                      key={`sortable-question-${key}`}
                      id={key}
                      index={index}
                      order={surveys[key]?.order}
                      surveys={surveys}
                      existingQuestions={existingQuestions}
                      existingFields={existingFields}
                      editing={editing}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </Accordion>
            <Add />
          </UpgradePath>
        </Card.Body>
      </Card>
    </>
  );
}

export default Surveys;
