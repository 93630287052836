import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TypeAnimation } from "react-type-animation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Login from "../Login";
import MicrosoftLogin from "../MicrosoftLogin";
import "./Landing.css";
import YouTube from "react-youtube";
import Passwordless from "./Passwordless/Passwordless";
import BentoGrid from "./BentoGrid/BentoGrid";

function _Landing() {
  const Button = window.calendbookUI.Button;
  const Icon = window.calendbookUI.Icon;
  const PricingCard = window.calendbookUI.PricingCard;
  const ComparisonTable = window.calendbookUI.ComparisonTable;
  const Accordion = window.calendbookUI.Accordion;
  const root = document.getElementById("root");
  const html = document.getElementById("html");
  root.setAttribute("data-theme", "light");
  html.setAttribute("data-theme", "light");
  // useTranslation parameter is an array of namespaces
  // namespaces are the categories object in the translation json
  // example:
  // landing:{title:"this is the title", description:"this is the description"}
  // useful for loading specific translations for specific components
  const { i18n, t } = useTranslation(["landing", "common", "upgrade"]);
  const getUserLanguage = () => {
    try {
      return i18n.language.substring(0, 2);
    } catch (e) {
      return "en";
    }
  };
  const [pricingCadency, setPricingCadency] = useState("yearly");
  const [loggingProvider, setLoggingProvider] = useState("none");
  try {
    window.tidioChatApi.show();
  } catch {}

  useEffect(() => {
    document.title = "Calendbook - Share your Calendar with a Link";
  }, []);

  return (
    <>
      <Container className="col-lg-10 col-md-11 col-sm-12 h-100">
        <div className="tw-my-32 tw-text-center">
          <div className=" tw-max-w-[768px] tw-mx-auto tw-my-10 tw-gap-4 tw-flex tw-flex-col">
            <h1 className="tw-leading-none tw-text-6xl tw-font-extrabold tw-text-gray-900">
              {t("title")}
              <br />
              <TypeAnimation
                sequence={[
                  t("titleSequence.1"),
                  1000,
                  t("titleSequence.2"),
                  1000,
                  t("titleSequence.3"),
                  1000,
                  t("titleSequence.4"),
                  1000,
                  t("titleSequence.5"),
                  1000,
                  t("titleSequence.6"),
                  1000,
                ]}
                preRenderFirstString={true}
                speed={50}
                repeat={Infinity}
                className="tw-text-primary-500"
              />
            </h1>
            <div className="tw-text-xl tw-font-normal tw-text-gray-500">
              {t("description")}
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-items-center">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-1 md:tw-gap-2">
              {(loggingProvider === "none" || loggingProvider === "google") && (
                <div className="my-3" style={{ width: 350 }}>
                  {
                    <Login
                      redirect="/"
                      setLoggingProvider={setLoggingProvider}
                    />
                  }
                </div>
              )}
              {(loggingProvider === "none" ||
                loggingProvider === "microsoft") && (
                <div className="my-3" style={{ width: 350 }}>
                  {
                    <MicrosoftLogin
                      redirect="/"
                      setLoggingProvider={setLoggingProvider}
                    />
                  }
                </div>
              )}
            </div>
            <div>
              {(loggingProvider === "none" ||
                loggingProvider === "passwordless") && (
                <Passwordless setLoggingProvider={setLoggingProvider} />
              )}
            </div>
          </div>
        </div>

        <Row>
          <Col className="py-5 tw-text-center">
            <h3 className="tw-text-center tw-text-4xl tw-font-bold tw-text-gray-900">
              {t("socialProof.title")}
            </h3>
            <div className="m-4 d-flex justify-content-center flex-column flex-md-row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 20,
                  justifyContent: "center",
                }}
                className="my-3"
              >
                <i
                  style={{
                    fontSize: 48,
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: 20,
                  }}
                  className="fa-solid fa-users"
                ></i>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontWeight: 900, fontSize: 24 }}>
                    {t("socialProof.customers.quantity")}
                  </span>
                  <span style={{ fontWeight: 300 }}>
                    {t("socialProof.customers.unit")}
                  </span>
                </div>
              </div>

              <a
                style={{ marginRight: 20 }}
                className="mx-auto my-3"
                href="https://www.capterra.com/reviews/343567/Calendbook?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
              >
                {" "}
                <img
                  style={{ width: 200 }}
                  border="0"
                  src="https://assets.capterra.com/badge/c61e3f861e4ae35d055bb5967e386481.svg?v=2290534&p=343567"
                />{" "}
              </a>
              <a
                className="mx-auto my-3"
                href="https://www.capterra.com/p/343567/Calendbook/"
              >
                {" "}
                <img
                  style={{ marginRight: 20, width: 75 }}
                  border="0"
                  src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/ca-value-2023.png"
                />{" "}
              </a>
              <a
                className="mx-auto my-3"
                href="https://www.capterra.com/p/343567/Calendbook/"
              >
                {" "}
                <img
                  style={{ marginRight: 20, width: 75 }}
                  border="0"
                  src="https://capterra.s3.amazonaws.com/assets/images/gdm-badges/ca-ease_of_use-2023.png"
                />{" "}
              </a>
              <a
                className="mx-auto my-3"
                href="https://www.wix.com/app-market/calendbook"
                target="_blank"
              >
                <img
                  src="/app-assets/img/wix-logo.png"
                  alt="Calendbook & Wix App"
                  width="200px"
                />
              </a>
            </div>
            <div className="m-4 d-flex justify-content-center">
              {t("socialProof.trello.title")}
            </div>
            <center>
              <a
                style={{
                  display: "block",
                  width: 150,
                  border: "1px solid",
                  padding: 10,
                  textDecoration: "none",
                  color: "var(--color-text)",
                  borderRadius: 10,
                }}
                href="https://app.getuservotes.com/public-board/840705cd-c672-4e05-a5bd-41c7a32ebaec"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("socialProof.trello.cta")}
              </a>
            </center>
          </Col>
        </Row>

        <Row sm={12} className="my-5 text-center">
          <Col sm={12}>
            <h3 className="tw-text-center tw-text-4xl tw-font-bold tw-text-gray-900">
              {t("steps.title")}
            </h3>
          </Col>
          <Col sm={12} md={4} className="my-5">
            <div>
              <i className="fa-solid fa-calendar-days h1"></i>
            </div>
            <div className="mt-2">{t("steps.1")}</div>
          </Col>
          <Col sm={12} md={4} className="my-5">
            <div>
              <i className="far fa-paper-plane h1"></i>
            </div>
            <div className="mt-2">{t("steps.2")}</div>
          </Col>
          <Col sm={12} md={4} className="my-5">
            <div>
              <i className="far fa-calendar-check h1"></i>
            </div>
            <div className="mt-2">{t("steps.3")}</div>
          </Col>
        </Row>

        <Row sm={12} className="my-5">
          <Col sm={12}>
            <h3 className="tw-text-center tw-text-4xl tw-font-bold tw-text-gray-900">
              {t("howItWorks.title")}
            </h3>
          </Col>
          <Col sm={12} className="mt-5 d-flex justify-content-center">
            <YouTube
              videoId={
                getUserLanguage() === "es" ? "Gt0dkUe4qco" : "MIL8h8tirkU"
              }
              containerClassName={"youtubeContainer"}
            />
          </Col>

          <Col sm={12} className="my-5">
            <h4 className="tw-text-center tw-text-4xl tw-font-bold tw-text-gray-900">
              {t("howItWorks.1.title")}
            </h4>
            <div className="tw-text-center tw-mt-2" style={{ fontWeight: 300 }}>
              {t("howItWorks.1.subtitle")}
            </div>
            {(loggingProvider === "none" || loggingProvider === "google") && (
              <div className="my-2 d-flex justify-content-center">
                {<Login redirect="/" setLoggingProvider={setLoggingProvider} />}
              </div>
            )}
          </Col>

          {/* Bento grid for features */}
          <BentoGrid />
        </Row>

        {/* tailwind integrations section */}
        <div className="tw-hidden lg:tw-block tw-max-w-7xl tw-mx-auto  tw-my-50">
          <div className="tw-mb-20">
            <h1 className="tw-text-center tw-mb-4 tw-text-4xl tw-font-extrabold tw-leading-none tw-tracking-tight tw-text-gray-900 md:tw-text-5xl lg:tw-text-6xl dark:tw-text-white">
              {t("integrations.title")}
            </h1>
            <p className="tw-text-center tw-mb-6 tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl sm:tw-px-16 xl:tw-px-48 dark:tw-text-gray-400">
              {t("integrations.subtitle")}
            </p>
          </div>

          <div className="tw-flex">
            <div className="tw-grid tw-grid-cols-2  tw-gap-[61px]">
              <div className="tw-inline-flex tw-gap-6">
                <div className="tw-border-[1.5px] tw-justify-center tw-flex tw-rounded-3xl tw-w-32 tw-h-32 tw-bg-white">
                  <div className="tw-w-[128px] tw-h-[128px] tw-m-auto tw-flex tw-items-center tw-justify-center">
                    <div className="tw-bg-[#635BFF] tw-rounded-xl tw-w-[71.11px] tw-h-[71.11px] tw-flex tw-items-center tw-justify-center">
                      <img src="/app-assets/png/stripe-logo.png" />
                    </div>
                  </div>
                </div>
                <div className="tw-text-start tw-flex tw-items-center tw-justify-center">
                  {t("integrations.integration.stripe.description")}
                </div>
              </div>
              <div className="tw-inline-flex tw-gap-6">
                <div className="tw-border-[1.5px] tw-justify-center tw-flex tw-rounded-3xl tw-w-32 tw-h-32 tw-bg-white">
                  <div className="tw-w-[128px] tw-h-[128px] tw-m-auto tw-flex tw-items-center tw-justify-center">
                    <div className="tw-rounded-xl tw-w-[71.11px] tw-h-[71.11px] tw-flex tw-items-center tw-justify-center">
                      <img src="/app-assets/png/google-calendar-logo.png" />
                    </div>
                  </div>
                </div>
                <div className="tw-text-start tw-flex tw-items-center tw-justify-center">
                  {t("integrations.integration.calendar.description")}
                </div>
              </div>
              <div className="tw-inline-flex tw-gap-6">
                <div className="tw-border-[1.5px] tw-justify-center tw-flex tw-rounded-3xl tw-w-32 tw-h-32 tw-bg-white">
                  <div className="tw-w-[128px] tw-h-[128px] tw-m-auto tw-flex tw-items-center tw-justify-center">
                    <div className="tw-rounded-xl tw-w-[71.11px] tw-h-[71.11px] tw-flex tw-items-center tw-justify-center">
                      <img src="/app-assets/png/google-meet-logo.png" />
                    </div>
                  </div>
                </div>
                <div className="tw-text-start tw-flex tw-items-center tw-justify-center">
                  {t("integrations.integration.meet.description")}
                </div>
              </div>
              <div className="tw-inline-flex tw-gap-6">
                <div className="tw-border-[1.5px] tw-justify-center tw-flex tw-rounded-3xl tw-w-32 tw-h-32 tw-bg-white">
                  <div className="tw-w-[128px] tw-h-[128px] tw-m-auto tw-flex tw-items-center tw-justify-center">
                    <div className="tw-bg-[#FF4F00] tw-rounded-xl tw-w-[71.11px] tw-h-[71.11px] tw-flex tw-items-center tw-justify-center">
                      <img src="/app-assets/png/zapier-logo.png" />
                    </div>
                  </div>
                </div>
                <div className="tw-text-start tw-flex tw-items-center tw-justify-center">
                  {t("integrations.integration.zapier.description")}
                </div>
              </div>
              <div className="tw-inline-flex tw-gap-6">
                <div className="tw-border-[1.5px] tw-justify-center tw-flex tw-rounded-3xl tw-w-32 tw-h-32 tw-bg-white">
                  <div className="tw-w-[128px] tw-h-[128px] tw-m-auto tw-flex tw-items-center tw-justify-center">
                    <div className="tw-rounded-xl tw-w-[71.11px] tw-h-[71.11px] tw-flex tw-items-center tw-justify-center">
                      <img src="/app-assets/png/zoom-logo.png" />
                    </div>
                  </div>
                </div>
                <div className="tw-text-start tw-flex tw-items-center tw-justify-center">
                  {t("integrations.integration.zoom.description")}
                </div>
              </div>
              <div className="tw-inline-flex tw-gap-6">
                <div className="tw-border-[1.5px] tw-justify-center tw-flex tw-rounded-3xl tw-w-32 tw-h-32 tw-bg-white">
                  <div className="tw-w-[128px] tw-h-[128px] tw-m-auto tw-flex tw-items-center tw-justify-center">
                    <div className="tw-bg-black tw-rounded-xl tw-w-[71.11px] tw-h-[71.11px] tw-flex tw-items-center tw-justify-center">
                      <Icon
                        name="plus"
                        fillColor="white"
                        width="35"
                        height="35"
                      />
                    </div>
                  </div>
                </div>
                <div className="tw-text-start tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-5">
                  <div className="tw-text-start">
                    {t("integrations.request.title")}
                  </div>
                  <div>
                    {" "}
                    <Button
                      outline
                      buttonText={t("integrations.request.CTA")}
                      rightIconStyle="chevron-right"
                      showRightIcon
                      onClick={() =>
                        window.open(
                          "https://gignlvdncn6.typeform.com/to/sthPsbx4",
                          "_blank"
                        )
                      }
                    />{" "}
                  </div>
                </div>
              </div>
            </div>

            {/*
              --- ORBIT ---
              <div className="tw-w-[299px]">
                <div className="orbit">
                  <ul className="orbit-wrap">
                    <li className="orbit-center"> <i className="orbit-center__icon" /> </li>
                    <li>
                      <ul className="ring-0">
                        <li> <i className="orbit-icon fa fa-git" /> </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="ring-1">
                        <li> <i className="orbit-icon fa fa-html5" /> </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="ring-2">
                        <li> <i className="orbit-icon fa fa-windows" /> </li>
                      </ul>
                    </li>
                    <li>
                      <ul className="ring-3">
                        <li> <i className="orbit-icon fa fa-heart-o" /> </li>
                      </ul>
                    </li>
                  </ul>
                </div>

              </div>
              */}
          </div>
        </div>
        {/* end tailwind integrations section */}

        <div id="pricing" className="tw-text-center tw-gap-4 tw-mt-10">
          <div className="tw-my-10">
            <span className="tw-bg-gray-200 tw-text-gray-800 tw-text-sm tw-font-medium tw-me-2 tw-px-2.5 tw-py-0.5 tw-rounded tw-dark:bg-gray-700 tw-dark:text-gray-300">
              {t("pricing.anchor")}
            </span>
          </div>

          <div className="tw-mb-20">
            <h1 className="tw-text-center tw-mb-4 tw-text-4xl tw-font-extrabold tw-leading-none tw-tracking-tight tw-text-gray-900 md:tw-text-5xl lg:tw-text-6xl dark:tw-text-white">
              {t("pricing.title")}
            </h1>
            <p className="tw-text-center tw-mb-6 tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl sm:tw-px-16 xl:tw-px-48 dark:tw-text-gray-400">
              {t("pricing.subtitle")}
            </p>
          </div>
        </div>

        <div className="tw-my-20 tw-mx-auto tw-w-max tw-bg-gray-200 tw-p-0.5 tw-gap-0.5 tw-rounded-full tw-flex tw-flex-row">
          <Button
            buttonText={t("pricing.cards.cadency.monthly")}
            color={pricingCadency === "monthly" ? "dark" : "alternative"}
            onClick={() => setPricingCadency("monthly")}
          />
          <Button
            buttonText={t("pricing.cards.cadency.yearlyDiscounted", {
              discount: "50",
            })}
            color={pricingCadency === "yearly" ? "dark" : "alternative"}
            onClick={() => setPricingCadency("yearly")}
          />
        </div>

        <div className="tw-my-10 tw-items-center md:tw-items-stretch tw-flex tw-flex-col md:tw-flex-row tw-gap-8">
          <PricingCard
            variant="secondary"
            plan="Free"
            description={t("pricing.cards.free.description")}
            currency={i18n.language.substring(0, 2) === "en" ? "$" : "€"}
            price="0"
            cadency={t("pricing.cards.cadency.month")}
            callback={() => window.scrollTo(0, 0)}
            features={[
              t("pricing.cards.free.0"),
              t("pricing.cards.free.1"),
              t("pricing.cards.free.2"),
              t("pricing.cards.free.3"),
              t("pricing.cards.free.4"),
              t("pricing.cards.free.5"),
            ]}
          />
          <PricingCard
            variant="secondary"
            plan="Premium"
            description={t("pricing.cards.premium.description")}
            currency={i18n.language.substring(0, 2) === "en" ? "$" : "€"}
            price={pricingCadency === "monthly" ? "5" : "2"}
            cadency={t("pricing.cards.cadency.month")}
            callback={() => window.scrollTo(0, 0)}
            features={[
              t("pricing.cards.premium.0"),
              t("pricing.cards.premium.1"),
              t("pricing.cards.premium.2"),
              t("pricing.cards.premium.3"),
              t("pricing.cards.premium.4"),
              t("pricing.cards.premium.5"),
            ]}
          />
          <PricingCard
            variant="primary"
            plan="Professional"
            description={t("pricing.cards.professional.description")}
            currency={i18n.language.substring(0, 2) === "en" ? "$" : "€"}
            price={pricingCadency === "monthly" ? "10" : "5"}
            cadency={t("pricing.cards.cadency.month")}
            callback={() => window.scrollTo(0, 0)}
            features={[
              t("pricing.cards.professional.0"),
              t("pricing.cards.professional.1"),
              t("pricing.cards.professional.2"),
              t("pricing.cards.professional.3"),
              t("pricing.cards.professional.4"),
              t("pricing.cards.professional.5"),
            ]}
          />
        </div>

        <div className="tw-gap-2 tw-hidden md:tw-flex tw-justify-center tw-flex-col">
          <button
            type="button"
            className="tw-my-12 tw-text-base tw-font-medium tw-bg-transparent tw-mx-auto tw-items-center tw-inline-flex tw-gap-3"
            data-collapse-toggle="comparison-content"
            aria-expanded="false"
            aria-controls="comparison-content"
          >
            {t("pricing.comparison.title")}
            <svg
              width="15"
              height="15"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99957 15C9.30943 15 8.63894 14.7188 8.16095 14.23L1.18625 7.1038C0.692547 6.59881 0.720048 5.80756 1.2478 5.33632C1.77555 4.86757 2.60581 4.89257 3.0982 5.39632L9.99957 12.4463L16.9009 5.39632C17.3933 4.89132 18.2249 4.86632 18.7513 5.33632C19.2791 5.80756 19.3079 6.59881 18.8142 7.1038L11.8395 14.23C11.3602 14.7188 10.6897 15 9.99957 15Z"
                fill="#000"
              />
            </svg>
          </button>

          <div id="comparison-content" className="hidden">
            <ComparisonTable
              plans={[
                {
                  name: t("pricing.comparison.unlimitedBookings"),
                  free: true,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.googleCalendarSync"),
                  free: true,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.googleMeetEvents"),
                  free: true,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.stripe"),
                  free: true,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.unlimitedEventTypes"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.customEventDuration"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.customEmailNotifications"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.advancedEventReminders"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.calendarConnections"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.embed"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.zoom"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.wix"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.salesforza"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.questions"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.team"),
                  free: false,
                  premium: true,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.unlimitedLeadsRetention"),
                  free: false,
                  premium: false,
                  professional: true,
                },

                {
                  name: t("pricing.comparison.zapier"),
                  free: false,
                  premium: false,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.webhooks"),
                  free: false,
                  premium: false,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.redirect"),
                  free: false,
                  premium: false,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.timeslotHop"),
                  free: false,
                  premium: false,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.bookingLimit"),
                  free: false,
                  premium: false,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.notice"),
                  free: false,
                  premium: false,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.lastAvailability"),
                  free: false,
                  premium: false,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.branding"),
                  free: false,
                  premium: false,
                  professional: true,
                },
                {
                  name: t("pricing.comparison.eventApproval"),
                  free: false,
                  premium: false,
                  professional: true,
                },
              ]}
            />
          </div>
        </div>
        <h1 className="tw-my-20 tw-text-center tw-mb-4 tw-text-4xl tw-font-extrabold tw-leading-none tw-tracking-tight tw-text-gray-900 md:tw-text-5xl lg:tw-text-6xl dark:tw-text-white">
          {t("FAQ.title")}
        </h1>
        <div className="tw-my-20">
          <Accordion
            items={[
              {
                content: [t("FAQ.1.answer")],
                title: t("FAQ.1.question"),
              },
              {
                content: [t("FAQ.2.answer")],
                title: t("FAQ.2.question"),
              },
              {
                content: [t("FAQ.4.answer")],
                title: t("FAQ.4.question"),
              },
              {
                content: [t("FAQ.5.answer")],
                title: t("FAQ.5.question"),
              },
            ]}
          />
        </div>

        <div className="tw-mb-20">
          <h1 className="tw-text-center tw-mb-4 tw-text-4xl tw-font-extrabold tw-leading-none tw-tracking-tight tw-text-gray-900 md:tw-text-5xl lg:tw-text-6xl dark:tw-text-white">
            {t("bottomCta.title")}
          </h1>
          <p className="tw-text-center tw-mb-6 tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl sm:tw-px-16 xl:tw-px-48 dark:tw-text-gray-400">
            {t("bottomCta.subtitle")}
          </p>
          {(loggingProvider === "none" || loggingProvider === "google") && (
            <div className="my-4 d-flex justify-content-center">
              {<Login redirect="/" setLoggingProvider={setLoggingProvider} />}
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export default _Landing;
