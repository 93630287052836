import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import UpgradeBadge from "../../../Utilities/UpgradePath/UpgradeBadge";

function LastAvailable({
  availability,
  setLastAvailabilityDate,
  setLastAvailabilityTime,
}) {
  const { t } = useTranslation(["eventCreator"]);

  const disableLastAvailableDate = () => {
    setLastAvailabilityDate("");
    setLastAvailabilityTime("00:00");
  };

  return (
    <Form.Group
      as={Row}
      controlId="event-last-availability"
      className="mt-3 mx-2"
    >
      <Form.Label column sm={2} className="mt-auto">
        <UpgradeBadge featureName="event-last-availability" />
        <br />
        {t("availability.lastAvailability.title")}
      </Form.Label>
      <Col sm={10} lg={6}>
        <Row>
          <Row>
            <small style={{ color: "var(--color-secondary)" }}>
              {t("availability.lastAvailability.hint")}
            </small>
          </Row>
          <Col xs={6}>
            <Form.Control
              className="dark-field text-center"
              name="last-availability-date"
              type="date"
              value={availability?.lastAvailability?.date || ""}
              onChange={(e) => setLastAvailabilityDate(e.target.value)}
            />
          </Col>
          <Col xs={5} className="d-flex align-items-center">
            <Form.Control
              className="dark-field text-center"
              name="last-availability-time"
              type="time"
              value={availability?.lastAvailability?.time || ""}
              onChange={(e) => setLastAvailabilityTime(e.target.value)}
            />
            <Button
              style={{
                border: "none",
                background: "transparent",
                color: "var(--color-text)",
              }}
              onClick={disableLastAvailableDate}
            >
              <i className="far fa-trash-alt my-auto"></i>
            </Button>
          </Col>
        </Row>
      </Col>
    </Form.Group>
  );
}

export default LastAvailable;
